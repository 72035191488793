<template>
	<v-bottom-navigation app fixed grow shift v-model="currentOptionSelected" background-color="white" height="50" class="elevation-0 puimenubottombar nav-mobile">
		<v-btn v-if="showHomeBtn" color="primary" text value="home" @click.native="goToHome()" ref="btnMobileHome">
			<v-icon>far fa-home</v-icon>
		</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="primary" text value="bars" @click.native="goToMenu()" ref="btnMobileMenu">
			<v-icon>far fa-bars</v-icon>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>
export default {
	name: 'PuiMenuIconBottomBar',
	data: () => ({
		currentOptionSelected: 'home',
		showHomeBtn: false,
		showMenu: false
	}),
	props: {
		menuJSON: {
			type: [Object, Array]
		}
	},
	computed: {
		getHomeUrl() {
			return '/' + this.$store.getters.appDefaultEntryPoint;
		},
		treeMenuItemSelected() {
			return this.$store.state.menu.treeMenuItemSelected;
		}
	},
	watch: {
		treeMenuItemSelected(treeMenuOptionSelected) {
			if (treeMenuOptionSelected && (treeMenuOptionSelected.component || treeMenuOptionSelected.model)) {
				this.goToMenu();
			}
		}
	},
	methods: {
		goToHome() {
			this.showMenu = false;
			this.$store.state.menu.expanded = this.showMenu;
			this.$store.commit('puiMenuExpandMenu', this.showMenu);
			this.$router.push({ path: this.getHomeUrl });
			this.$store.commit('puiMenuIconBarSelected', { node: null });
		},
		deselectLastSelectedItem(treeMenuOption) {
			if (this.currentOptionSelected !== null && treeMenuOption.item.label !== this.currentOptionSelected.item.label) {
				this.$set(this.currentOptionSelected, 'isSelected', false);
			}
		},
		goToMenu() {
			if (this.showMenu) {
				this.showMenu = false;
			} else {
				this.showMenu = true;
			}
			if (this.$refs.btnMobileMenu) {
				this.$refs.btnMobileMenu.isActive = this.showMenu;
			}
			if (this.$refs.btnMobileHome) {
				this.$refs.btnMobileHome.isActive = !this.showMenu;
			}
			this.$store.state.menu.expanded = this.showMenu;
			this.$store.commit('puiMenuExpandMenu', this.showMenu);
		}
	},
	created() {
		var conf = this.$store.getters.getMenuConf;
		this.showHomeBtn = conf.homeBtn ? conf.homeBtn : true;
	}
};
</script>

<style lang="postcss" scoped>
.nav-mobile.v-bottom-nav .v-btn.v-btn--active {
	max-width: fit-content;
}
.nav-mobile.v-bottom-nav .v-btn.v-btn--active .v-icon {
	padding-top: 16px !important;
}
.nav-mobile.v-bottom-nav .v-btn .v-icon {
	margin-top: -10px;
}
</style>
